@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

* {
    font-family: 'Sora', sans-serif;
    /* font-family: "Open Sans", sans-serif; */
    box-sizing: border-box;
    margin: 0;
    padding: 0;
	scroll-behavior: smooth;
    -webkit-overflow-scrolling: smooth;
}

body {
    background-color: #000;
    overscroll-behavior: none;
    font-size: 1rem;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    overflow-x: hidden;
}

@media (max-width: 767px) {
    body {
        font-size: 1.5rem;
        transition: 0.2s;
        -webkit-transition: 0.2s;
    }

    p {
        font-size: 1.25rem;
    }
}
