.DrawerToggle {
    width: 40px;
    height: 56px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle div {
    width: 90%;
    height: 3px;
    background-color: white;
    transition: 0.4s;
}

.DrawerToggle:hover div {
    transition: all 0.2s ease-in-out;
    background-color: #FF4747;
}

.Close.FirstBar {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.Close.SecondBar {
    opacity: 0;
}

.Close.ThirdBar {
  -webkit-transform: rotate(45deg) translate(-10px, -8px);
  transform: rotate(45deg) translate(-10px, -8px);
}

@media (min-width: 768px) {
    .DrawerToggle {
        display: none;
    }
}
