.SideDrawer {
    position: fixed;
    width: 270px;
    height: 100%;
    right: -270px;
    top: 0;
    z-index: 200;
    background-color: #1C1C1C;
    padding: 0 16px;
    box-sizing: border-box;
    transition: transform 0.2s ease-out;
    -webkit-transition: transform 0.2s ease-out;
}

.SideDrawerNav {
    position: sticky;
    top: 8px;
}

.Logo {
    height: 75px;
    width: auto;
    transition: 0.4s;
    margin-bottom: 15px;
}

@media (min-width: 768px) {
    .SideDrawer {
        display: none;
    }
}
