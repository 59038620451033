.Toolbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    border-bottom: 0.1px solid #1C1C1C;
    z-index: 101;
    transition: 0.4s, transform 0.2s ease-out;
    -webkit-transition: 0.4s, transform 0.2s ease-out;
}

.Big {
    background-color: transparent;
    height: 100px;
}

.Small {
    background-color: #1C1C1C;
    height: 50px;
}

.Toolbar nav {
    padding: 0;
}

.Logo {
    height: 100%;
    padding: 8px;
    padding-left: 0;
}

.DesktopOnly {
    padding: 8px;
    padding-left: 0;
}

@media (max-width: 767px) {
    .DesktopOnly {
        display: none;
    }

    .Big {
        height: 50px;
    }
}
