.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a {
    color: #D5D5D7;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: #FF4747;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
}

@media (min-width: 768px) {

    .NavigationItem {
        margin: 0 0.5vw;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }

    .NavigationItem a {
        color: #D5D5D7;
        height: 100%;
        padding: 4px 10px;
        border-bottom: 4px solid transparent;
        transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
    }

    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        transition: all 0.4s ease-in-out;
        border-bottom: 4px solid #FF4747;
        color: #FF4747;
    }
}
