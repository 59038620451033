.Logo {
    color: #FFF;
    font-weight: bolder;
    height: 100%;
    width: auto;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
}

.Logo a {
    text-decoration: none;
    height: 75%;
    width: auto;
    margin-right: 5px;
    transition: 0.2s;
    -webkit-transition: 0.2s;
}

.Logo span {
    display: none;
    transition: 0.2s;
    -webkit-transition: 0.2s;
}

.Logo img {
    height: 100%;
    width: auto;
}

.Logo img:hover, .Logo img:active {
    content: url('../../assets/images/logo_hover.png');
}

@media (min-width: 381px) and (max-width: 767px) {
    .Logo span {
        display: block;
        transition: 0.2s;
        -webkit-transition: 0.2s;
    }

    .Logo a {
        height: 90%;
        transition: 0.2s;
        -webkit-transition: 0.2s;
    }
}
