.Footer {
    color: #fff;
    background-color: #1c1c1c;
    padding: 1vh;
    text-align: center;
}

.Container {
    margin: auto;
    min-width: 100px;
    max-width: 400px;
}

.Text {
    margin: 2vh 0;
}

.Text h1 {
    margin: 1vh 0;
}

.Links {
    margin: 0 2.5em;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.Links li {
    margin: 10px 0.5vw;
    display: flex;
    height: 100%;
    width: auto;
    cursor: pointer;
    align-items: center;
}

.Links li:hover {
    color: #FF4747;
}
