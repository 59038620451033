.GotoTop {
    position: fixed;
    bottom: 12px;
    right: 12px;
    color: #1C1C1C;
    background: #FF4747;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    backface-visibility: hidden;
    text-decoration: none;
    text-align: center;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    z-index: 100
}

.HideGotoTop {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.4s, opacity 0.4s linear;
    -webkit-transition: visibility 0.4s, opacity 0.4s linear;
}

.ShowGotoTop {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.4s, opacity 0.4s linear;
    -webkit-transition: visibility 0.4s, opacity 0.4s linear;
}

.GotoTop:hover, .GotoTop:active {
    color: #FFF;
    transition: 0.4s;
    -webkit-transition: 0.4s;
}
