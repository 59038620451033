.Content {
    transition: transform 0.2s ease-out;
    -webkit-transition: transform 0.2s ease-out;
}

@media (max-width: 768px) {

    .Open {
        transform: translateX(-270px);
        -webkit-transform: translateX(-270px);
    }
    
    .Close {
        transform: translateX(0);
        -webkit-transform: translateX(0);
    }
}